<template>
	<div class="main-content">
		<vue-html2pdf
			ref="html2Pdf"
			:enable-download="true"
			:filename="`${$t('Agri Input Payment', 'Agri Input Payment')} (${$route.params.id})`"
			:float-layout="true"
			:manual-pagination="false"
			:paginate-elements-by-height="1400"
			:pdf-quality="2"
			:preview-modal="false"
			:show-layout="false"
			pdf-content-width="100%"
			pdf-format="a4"
			pdf-orientation="portrait"
		>
			<section slot="pdf-content">
				<invoice
					v-if="disburseDetail && cvcHead && payment && params.disburseId"
					:cvc-head="cvcHead"
					:disburse-detail="disburseDetail"
					:disburse-id="params.disburseId"
					:payment="payment"
				/>
			</section>
		</vue-html2pdf>
		<div v-if="!is.showing.paymentInstruction">
			<ktv-breadcrumb :folder="$t('Premium Points', 'Premium Points')" :page="hubArea" :title="$t('Agri Input Payment', 'Agri Input Payment')" />
			<b-row>
				<b-col md="12 mb-30">
					<b-row class="mb-4">
						<b-col>
							<router-link v-b-tooltip.hover :to="{ name: 'Premium Points Agri Input Payment List' }">
								<ktv-back :label="$t('Back to agri input payment list', 'Back to agri input payment list')" />
							</router-link>
						</b-col>
					</b-row>
					<div>
						<b-row>
							<b-col>
								<h2>{{ $t("Agri Input Payment Detail", "Agri Input Payment Detail") }} ({{ $route.params.id }})</h2>
							</b-col>
						</b-row>
						<b-row class="px-16">
							<div class="text-14">
								<span class="text-gray-400">{{ $t("Hub Area", "Hub Area") }}: </span>
								<span class="font-weight-600 text-gray-500">{{ disburseDetail && disburseDetail.hubArea ? disburseDetail.hubArea : "-" }}</span>
							</div>
						</b-row>
						<b-row class="mt-16">
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Farmer", "Farmer") }}</div>
								<div class="font-weight-600 text-gray-500">{{ disburseDetailTotalRows | Number }}</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Premium Points", "Premium Points") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.totalPremium >= 0 ? idrFilter(Math.round(disburseDetail.totalPremium)) : "-" }}
								</div>
							</b-col>
							<b-col v-if="disburseDetail && disburseDetail.totalFee" class="col-md-auto text-14">
								<div class="text-gray-400">
									<span class="mr-1">{{ $t("Total Fee", "Total Fee") }}</span>
									<img id="total-fee" :src="icons.info" />
									<b-tooltip target="total-fee">
										{{
											`${$t("Fee", "Fee")} ${idrFilter(Math.round(disburseDetail.totalFee / disburseDetailTotalRows))}/${$t(
												"Farmer",
												"Farmer",
											).toLowerCase()}`
										}}
									</b-tooltip>
								</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.totalFee ? IDRFilter(Math.round(disburseDetail.totalFee)) : "-" }}
								</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Transaction Amount", "Transaction Amount") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.totalPremium >= 0 ? IDRFilter(Math.round(disburseDetail.totalPremium)) : "-" }}
								</div>
							</b-col>
							<b-col />
							<b-col class="col-md-auto text-14 text-right">
								<div class="text-gray-400">{{ $t("CVC Association", "CVC Association") }}</div>
								<div class="font-weight-600 text-gray-500">{{ disburseDetail && disburseDetail.cvcAssoc ? disburseDetail.cvcAssoc : "-" }}</div>
							</b-col>
							<b-col class="col-md-auto text-14 text-right">
								<div class="text-gray-400">{{ $t("Date Submitted", "Date Submitted") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.dateSubmitted ? getDate(disburseDetail.dateSubmitted) : "-" }}
								</div>
							</b-col>
						</b-row>
						<div class="mt-32">
							<ktv-table
								:columns="columns"
								:filter="false"
								:is-loading="is.loading"
								:line-numbers="false"
								:rows="disburseDetailData"
								:search-enabled="false"
								:search-placeholder="$t('Search by farmer name', 'Search by farmer name')"
								:total-rows="disburseDetailTotalRows"
								min-height="250px"
								mode="remote"
								@on-page-change="onPageChange"
								@on-per-page-change="onPerPageChange"
								@on-search="onSearch"
								@on-sort-change="onSortChange"
							>
								<template #actionleft>
									<div class="d-flex">
										<ktv-button
											:text="$t('Report Agri Input Payment', 'Report Agri Input Payment')"
											color="light"
											icon="download"
											@click="exportReport"
										/>
										<ktv-button
											:disabled="!disburseDetail || (disburseDetail && disburseDetail.statusDisburseType !== 235)"
											:text="$t('Export to Excel', 'Export to Excel')"
											:tooltip="
												!disburseDetail || (disburseDetail && disburseDetail.statusDisburseType !== 235)
													? $t(
														'Unable to export, disburse status has not been closed',
														'Unable to export, disburse status has not been closed',
													)
													: ''
											"
											class="ml-2"
											color="light"
											icon="download"
											@click="exportFile('xlsx')"
										/>
										<ktv-button
											:disabled="!disburseDetail || (disburseDetail && disburseDetail.statusDisburseType !== 235)"
											:text="$t('Export to PDF', 'Export to PDF')"
											:tooltip="
												!disburseDetail || (disburseDetail && disburseDetail.statusDisburseType !== 235)
													? $t(
														'Unable to export, disburse status has not been closed',
														'Unable to export, disburse status has not been closed',
													)
													: ''
											"
											class="ml-2"
											color="light"
											icon="download"
											@click="exportFile"
										/>
									</div>
								</template>
								<template #columns="{ props }">
									{{ $t(props.column.label, props.column.label) }}
								</template>
								<template #rows="{ props }">
									<span v-if="props.column.field === 'number'">{{ props.row.originalIndex + (params.page - 1) * params.limit + 1 }}</span>
									<span v-else-if="props.column.field === 'ptPremiumDisburseDetailId'">
										{{ props.row.ptPremiumDisburseDetailId ? props.row.ptPremiumDisburseDetailId : "-" }}
									</span>
									<span v-else-if="props.column.field === 'farmerName'">{{ props.row.farmerName ? props.row.farmerName : "-" }}</span>
									<span v-else-if="props.column.field === 'useFarmcloud'">
										{{ props.row.useFarmcloud ? (props.row.useFarmcloud === "1" ? "FarmCloud" : "Non FarmCloud") : "Not available" }}
									</span>
									<span v-else-if="props.column.field === 'premiumPoint'">
										{{ props.row.premiumPoint >= 0 ? idrFilter(Math.round(props.row.premiumPoint)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'agriInputQuantity'">
										{{ props.row.agriInputQuantity >= 0 ? idrFilter(props.row.agriInputQuantity) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'dateTransaction'">
										{{ props.row.dateTransaction ? getDate(props.row.dateTransaction) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'statusDescription'">
										{{ props.row.statusDescription ? props.row.statusDescription : "-" }}
									</span>
									<span v-else-if="props.column.field === 'statusDeliveryDescription'">
										{{ props.row.statusDeliveryDescription ? props.row.statusDeliveryDescription : "-" }}
									</span>
								</template>
							</ktv-table>
							<div class="mt-32" align="center">
								<ktv-button
									v-if="isShowingPaymentButton"
									:loading="is.loading"
									:text="$t('Continue to Payment', 'Continue to Payment')"
									class="mx-1"
									icon="credit-card"
									@click="showPaymentInstruction()"
								/>
								<ktv-button
									v-if="disburseDetail && cvcHead"
									:loading="is.loading"
									:text="$t('Download Invoice', 'Download Invoice')"
									class="mx-1"
									color="outline-primary"
									icon="download"
									@click="showInvoiceModal()"
								/>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
		<payment-instruction
			v-if="is.showing.paymentInstruction"
			:disburse="disburse"
			:payment-method-options="paymentMethodOptions"
			:show="is.showing.paymentInstruction"
			@close="closePaymentInstruction()"
		/>
	</div>
</template>
<script>
	import { KtvBack, KtvButton, KtvTable } from "@/components"
	import { every, map } from "underscore"
	import { mapActions, mapGetters, mapState } from "vuex"
	import Invoice from "./_Invoice"
	import PaymentInstruction from "./_PaymentInstruction"
	import VueHtml2pdf from "vue-html2pdf"

	import infoIcon from "@/assets/images/icon/info-gray.svg"

	const icons = {
		info: infoIcon,
	}

	export default {
		name: "PremiumPointsAgriInputPaymentDetail",
		metaInfo: {
			title: "View Premium Points Agri Input Payment",
		},
		components: { Invoice, KtvBack, KtvButton, KtvTable, PaymentInstruction, VueHtml2pdf },
		data() {
			return {
				columns: [
					{
						field: "number",
						label: "Number",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "ptPremiumDisburseDetailId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerName",
						label: "Farmer Name",
						thClass: "text-left",
						width: "200px",
					},
					{
						field: "useFarmcloud",
						label: "Farmer Type",
						thClass: "text-left",
						width: "150px",
					},
					{
						field: "premiumPoint",
						label: "Premium Points",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "agriInputQuantity",
						label: "Agri Input",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "dateTransaction",
						label: "Date Submitted",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "statusDescription",
						label: "Status",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "statusDeliveryDescription",
						label: "Status Delivery",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
				],
				disburse: {
					accordionAttributes: [],
					accountName: null,
					accountNumber: null,
					amount: 0,
					destinationId: null,
					destinationName: null,
					destinationType: null,
					note: null,
					paymentInstructions: [],
					paymentMethodId: 2,
					transactionId: null,
					virtualAccount: null,
				},
				icons: icons,
				is: {
					loading: false,
					showing: {
						farmerDetailModal: false,
						paymentInstruction: false,
					},
				},
				params: {
					disburseId: parseInt(String(this.$route.params.id).replaceAll("PRE", ""), 10) || null,
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
				payment: null,
				paymentMethodOptions: [],
			}
		},
		computed: {
			...mapGetters({
				disburseDetailData: "PREMIUMPOINT/disburseDetailData",
				disburseDetailTotalRows: "PREMIUMPOINT/disburseDetailTotalRows",
				token: "AUTHDATA/token",
			}),
			...mapState("PREMIUMPOINT", ["cvcHead", "disburseDetail"]),
			...mapState("PAYMENT", ["paymentMethodList"]),
			hubArea() {
				return this.disburseDetail?.hubArea
					? `${this.$t("Hub Area", "Hub Area")}: ${this.disburseDetail.hubArea}`
					: `${this.$t("Hub Area", "Hub Area")}: -`
			},
			isShowingPaymentButton() {
				let show = false

				if (this.disburseDetail) {
					if (
						parseInt(this.disburseDetail.statusDisburseType, 10) === 222 ||
						parseInt(this.disburseDetail.statusDisburseType, 10) === 223 ||
						parseInt(this.disburseDetail.statusDisburseType, 10) === 224
					) {
						show = false
					} else if (parseInt(this.disburseDetail.statusDisburseType, 10) === 221) {
						const isFarmerStatusSubmitted = every(this.disburseDetailData, (farmer) => parseInt(farmer.statusType, 10) === 234)
						const dateNow = new Date()
						let plusThreeDateDisburse = new Date(this.disburseDetail.premiumDisburseDate)
						plusThreeDateDisburse.setDate(plusThreeDateDisburse.getDate() + 3)

						if (isFarmerStatusSubmitted) {
							show = true
						} else if (dateNow > plusThreeDateDisburse) {
							show = true
						}
					}
				}

				return show
			},
		},
		watch: {
			"$route.params.id": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							this.getData()
						}
					} else {
						this.$swal(
							`${this.$t("Failed", "Failed")}!`,
							this.$t("Please make sure to input valid disburse id", "Please make sure to input valid disburse id"),
							"error",
						)

						this.$router.push({
							name: "Premium Points Agri Input Payment List",
						})
					}
				},
				immediate: true,
			},
			"disburseDetail.districtId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							this.getCVCHead(newValue)
						}
					}
				},
				immediate: true,
			},
			"paymentMethodList.premiumPoint": {
				deep: true,
				handler() {
					this.getPaymentMethodOptions()
				},
				immediate: true,
			},
			token: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						this.getPaymentReference("premium-point").then(() => {
							this.getPaymentReference("premium-point")
						})
					}
				},
				immediate: true,
			},
		},
		beforeDestroy() {
			this.resetCVCHead()
			this.resetDisburseDetail()
			this.payment = null
		},
		methods: {
			...mapActions({
				checkServiceCharge: "PAYMENT/checkServiceCharge",
				getCVCHead: "PREMIUMPOINT/getCVCHead",
				getDisburseDetailAgriInput: "PREMIUMPOINT/getDisburseDetailAgriInput",
				getPaymentReference: "PAYMENT/getPaymentReference",
				getPaymentStatus: "PAYMENT/getPaymentStatus",
				getReport: "PREMIUMPOINT/getReport",
				getTransactionReport: "PREMIUMPOINT/getTransactionReport",
				resetCVCHead: "PREMIUMPOINT/resetCVCHead",
				resetDisburseDetail: "PREMIUMPOINT/resetDisburseDetail",
				submitPayment: "PAYMENT/submitPayment",
				updateDisburseStatus: "PREMIUMPOINT/updateDisburseStatus",
			}),
			closePaymentInstruction() {
				this.is.showing = {
					...this.is.showing,
					paymentInstruction: false,
				}
			},
			downloadFile(url, fileName) {
				fetch(url)
					.then((response) => response.blob())
					.then((blob) => {
						const link = document.createElement("a")
						link.href = URL.createObjectURL(blob)
						link.download = fileName
						link.click()
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						this.$swal(err.name, errorMessage, "error")
					})
			},
			exportReport() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getReport({ disburseId: this.params.disburseId, type: "agriInputPayment" })
					.then((transactionReport) => {
						transactionReport.forEach(({ fileName, url }) => {
							const fileExtension = fileName.split(".").pop()

							const replacedFileName = `${this.$t("Report Agri Input Payment", "Report Agri Input Payment")} (${
								this.$route.params.id
							}).${fileExtension}`
							this.downloadFile(url, replacedFileName)
						})

						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			exportFile(extension = "pdf") {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getTransactionReport(this.params.disburseId)
					.then((transactionReport) => {
						transactionReport.forEach(({ fileName, url }) => {
							const fileExtension = fileName.split(".").pop()

							if (fileExtension === extension) {
								const name = String(fileName).toLowerCase()
								const searchCvc = String("report_cvc").toLowerCase()
								const searchTransaction = String("report_transaction").toLowerCase()
								const isCvc = name.indexOf(searchCvc) > -1
								const isTransaction = name.indexOf(searchTransaction) > -1

								if (isCvc) {
									const replacedFileName = `${this.$t("Report Agri Input Payment - CVC", "Report Agri Input Payment - CVC")} (${
										this.$route.params.id
									}).${fileExtension}`
									this.downloadFile(url, replacedFileName)
								} else if (isTransaction) {
									const replacedFileName = `${this.$t(
										"Report Agri Input Payment - Transaction",
										"Report Agri Input Payment - Transaction",
									)} (${this.$route.params.id}).${fileExtension}`
									this.downloadFile(url, replacedFileName)
								} else {
									this.downloadFile(url, fileName)
								}
							}
						})

						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getDisburseDetailAgriInput(this.params)
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getDate(timestamp) {
				const date = new Date(timestamp)

				return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`
			},
			getPaymentMethodOptions() {
				this.paymentMethodOptions = map(this.paymentMethodList.premiumPoint, (paymentMethod) => {
					return {
						text: `${paymentMethod.PaymentLabel} - ${paymentMethod.PaymentMethod}`,
						value: parseInt(paymentMethod.PaymentMethodID, 10),
					}
				})
			},
			idrFilter(amount) {
				return this.$options.filters.Number(amount)
			},
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch($event) {
				this.updateParams({ search: $event ? `farmerName=${$event}` : null })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showInvoiceModal() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getPaymentStatus({
					paymentMethodId: 2,
					type: "premium-point",
					uid: `${parseInt(String(this.params.disburseId), 10)}-disburse`,
				})
					.then((data) => {
						this.payment = Object.assign({}, data)

						this.$refs.html2Pdf.generatePdf()

						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Payment data not found", "Payment data not found"), "error")

						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			showPaymentInstruction() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.disburse = {
					...this.disburse,
					disburseId: this.params.disburseId,
				}

				this.checkServiceCharge({
					amount: Math.round(this.disburseDetail.totalPremium),
					paymentMethodId: 2,
					type: "premium-point",
				}).then((serviceCharge) => {
					this.submitPayment({
						accountName: this.cvcHead.accountHolder,
						accountNumber: this.cvcHead.accountNumber,
						amount: Math.round(serviceCharge.TotalPaid),
						bankCode: this.cvcHead.bankCode,
						bankName: this.cvcHead.bankName,
						destinationId: this.cvcHead.entityId,
						destinationName: this.cvcHead.name,
						destinationType: "CVC Head",
						id: `${parseInt(String(this.disburse.disburseId), 10)}-disburse`,
						note: "premium-point-agri-input-payment",
						paymentMethodId: 2,
						serviceCharge: 0, // paym-559: service charge removed
						serviceChargeType: serviceCharge.ServiceChargeType,
						totalServiceCharge: 0, // paym-559: service charge removed
						type: "premium-point",
					})
						.then((payment) => {
							this.updateDisburseStatus({
								disburseId: parseInt(String(this.disburse.disburseId), 10),
								flag: 2,
								virtualAccount: payment.PaymentDetail[0].VirtualAccount,
							})
								.then(() => {
									this.disburse = {
										...this.disburse,
										accordionAttributes: map(payment.PaymentDetail[0].PaymentInstruction, (_a, index) => {
											return {
												class: "i-Arrow-Down",
												"v-b-toggle": `'accordion-${index}'`,
											}
										}),
										accountName: this.cvcHead.accountHolder,
										amount: payment.TotalPaid,
										expiredDate: payment.PaymentDetail[0].ExpiredDate,
										paymentInstructions: payment.PaymentDetail[0].PaymentInstruction,
										serviceCharge: 0, // paym-559: service charge removed
										virtualAccount: payment.PaymentDetail[0].CompanyCode + payment.PaymentDetail[0].VirtualAccount,
									}

									this.is.showing = {
										...this.is.showing,
										paymentInstruction: true,
									}

									this.getData()
								})
								.catch(() => {
									this.is = {
										...this.is,
										loading: false,
									}
								})
						})
						.catch(() => {
							this.is = {
								...this.is,
								loading: false,
							}
						})
				})
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
